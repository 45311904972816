import './style.scss'
import React from 'react'
import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'
export default function ISDiverse({ zucca_mascot_image_3, graduate_image }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} id="is-diverse" className="master-wrap">
        <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} >

      <div className='bar c5' />

      <div className='left heading-logo-wrap padd padd-top-2 padd-bottom-2'>
        <div style={{ paddingBottom: "35%" }} className='aspect-ratio logo'>
          <Img fluid={zucca_mascot_image_3.localFile.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            objectPosition='50% 50%'
            className='Hero Text'
            alt='Next Logo'
          />
        </div>
        <div className='content fs-125 padd'>
          <h3 className='yellow uppercase h4'>listed #2 of the</h3>
          <h2 className='h1'>top 20 Most<br />
            Diverse<br />
            colleges
          </h2>
          <h3 className='yellow uppercase h4'>with more than 750 students</h3>
          <a href="https://www.universities.com/rankings/top-20-most-diverse-colleges-with-more-than-750-students?utm_campaign=later-linkinbio-universities_com&utm_content=later-21182686&utm_medium=social&utm_source=linkin.bio" target='_blank' rel='noopener noreferrer'>Universities.com, October 5, 2021 by Matt Cates</a>
        </div>
      </div>

      <div className="right">
        <div className='bg aspect-ratio-mobile'>
          <Img fluid={graduate_image.localFile.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            className='Hero Text'
            alt='Next Logo'
          />
        </div>
      </div>

      <div className='bar c5' />
</Intro>
    </div>
  )
}