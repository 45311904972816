import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

function MRUCampusLifeModal({ campus_life_lightbox }) {

  return (
    <div id="campus-life-modal" className="grid-12 c5 padd-top padd-bottom">

      <div className='span-12 padd-2 span-12-tablet'>

        <div className='intro outline fs-125'>
          <h2>Campus Life at MRU</h2>
        </div>

        <div className='grid-12 padd-top gap-1'>
          <div className='span-4 relative'>
            <div className='aspect-ratio'>
              <Img fluid={campus_life_lightbox.image1.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                className='Hero Text'
                alt='School of Education'
              />
            </div>
          </div>
          <div className='span-4 relative'>
            <div className='aspect-ratio'>
              <Img fluid={campus_life_lightbox.image2.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                className='Hero Text'
                alt='School of Education'
              />
            </div>
          </div>
          <div className='span-4 relative'>
            <div className='aspect-ratio'>
              <Img fluid={campus_life_lightbox.image3.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: 'cover' }}
                objectPosition='50% 50%'
                className='Hero Text'
                alt='School of Education'
              />
            </div>
          </div>
        </div>

        <div className='container fs-85'>
          <p className='padd-top'>
            At Miami Regional University we are more than an educational institution, we are a Family. We are bonded through our diverse cultures, our unique curiosity and passion for technology and information, and our desire to help each other and the community. The department of Campus Life works closely with students, alumni, and student services departments in everything at MRU. Our community events, student committees, on-campus activities and graduation ceremonies all contribute to the atmosphere, where diversity is not just accepted but embraced as a source of strength and enrichment.
          </p>
          <p>
            Campus Life connects students and alumni to available services, campus pride and traditions, student ceremonies, and social and cultural events both on and off campus.
          </p>
          <p>
            The department is led by Maria Gomez, Senior Director of Campus Life and Community Engagement. She can be reached at <a href="mailto:mru4you@mru.edu">mru4you@mru.edu</a>
          </p>
        </div>

      </div>
    </div>
  )
}

export default MRUCampusLifeModal