import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

function MRUHousingModal({ housing_lightbox }) {

  return (
    <div id="housing-modal" className="grid-12 c5 padd-2">
      <div className='span-3 span-12-tablet relative'>
        <div className='image-left bg'>
          <Img fluid={housing_lightbox.image.localFile.childImageSharp.fluid}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            className='Hero Text'
            alt='School of Education'
          />
        </div>
      </div>

      <div className='span-9 padd-2 span-12-tablet'>

        <div className='intro outline fs-125'>
          <h2>International <br /> Student Housing</h2>
        </div>

        <div className='container fs-85'>
          <p className='padd-top'>
            Miami Regional University does not have residential units on campus.
            The links below provide students with resources to identify rentals in Miami Florida.
          </p>

          <div className='logos flex padd-top padd-bottom'>
            {housing_lightbox?.logos_repeater?.map((item, i) => {
              return (
                <a key={i} href={item.link} target="_blank" rel="noreferrer noopener" className='single-logo'>
                  <div style={{ paddingBottom: "56%" }} className='aspect-ratio'>
                    <Img fluid={item.image.localFile.childImageSharp.fluid}
                      imgStyle={{ objectFit: 'contain' }}
                      objectPosition='50% 50%'
                      className='Hero Text'
                      alt='School of Education'
                    />
                  </div>
                </a>
              )
            })}
          </div>

          <p>
            If student would like to work with a realtor, MRU can facilitate the process and will assist, please contact <a href="mailto:mru4you@mru.edu">mru4you@mru.edu</a>
          </p>
        </div>

      </div>
    </div>
  )
}

export default MRUHousingModal