import './style.scss'
import { Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

export default function ISPrograms(props) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  const { mru_international_logo, mru_international_backdrop, zucca_mascot_image_1, program_links } = props

  return (
    <div ref={io} id="is-programs" className="master-wrap grid-12 c5 grid-12 padd padd-top-2 padd-bottom-2">
      <div style={{ zIndex: "-1" }} className='bg'>
        <Img fluid={mru_international_backdrop.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className='Hero Text'
          alt='Next Logo'
        />
      </div>

      <div className='span-6 span-12-tablet logo-with-copy'>
        <div className='limit'>
          <div className='logo-img'>
            <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} className="full-width">
              <div className='aspect-ratio square'>
                <Img fluid={mru_international_logo.localFile.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  className='Hero Text'
                  alt='Next Logo'
                />
              </div>
            </Intro>
          </div>
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={650} mounted={true} stay={true}>
          <div className='content fs-85' >
            <p>
              The Office of International Students is here to guide each of you through the process of studying in the United States. Our Team will assist you with the wide range of essential and practical steps you will need as you transition to our university. All of us at MRU are committed to treating all of our students with respect and compassion as we all learn from one another.
            </p>
            <div className='limit-zucca'>
              <div style={{ paddingBottom: "73%" }} className='aspect-ratio zucca'>
                <Img fluid={zucca_mascot_image_1.localFile.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  className='Hero Text'
                  alt='Next Logo'
                />
              </div>
            </div>
          </div>
          </Intro>
        </div>
      </div>

      <div className="span-6 span-12-tablet padd-top-2">
        <div className='right-bg padd'>
          <div className='intro outline'>
            <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} className="">
              <h1>Our <br />
                International<br />
                Programs</h1>
            </Intro>
          </div>

          {
            program_links?.map((item, i) => {
              return (
                <Intro key={i} visible={ioInView} in={{ fadeLeft: 500 }} delayIn={750 + 75 * i} mounted={true} stay={true} className="">
                  <div className='single-school padd padd-left-off'>
                    <div className='img'>
                      <div className='aspect-ratio'>
                        <Img fluid={item.image.localFile.childImageSharp.fluid}
                          imgStyle={{ objectFit: 'cover' }}
                          objectPosition='50% 50%'
                          className='Hero Text'
                          alt='School of Education'
                        />
                      </div>
                    </div>
                    <div className='content padd padd-top-off'>
                      <h4>{item.title}</h4>
                      <div className="links-container fs-85">
                        <a href={item?.link} target='_blank' rel='noopener noreferrer' className='button'>LEARN MORE<span className="btn-arrow"></span></a>
                      </div>
                    </div>
                  </div>
                </Intro>
              )
            })
          }

        </div>
      </div>
    </div>
  )
}