import './style.scss'
import React from 'react'
import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

export default function ISTeam({ team_ }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} id="is-team" className="c5 t padd-bottom-3">
      <div className='padd-2'>
        <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} >
          <div className='heading-main padd'>
            <h2>Meet your School Officials & Administrators</h2>
          </div>
        </Intro>
      </div>
      <div className='flex'>
        {team_?.map((item, i) => {
          return (
            <Intro key={i} visible={ioInView} in={{ fadeBottom: 500 }} delayIn={250 + 75 * i} mounted={true} stay={true} className="single-person">
              <div style={{ paddingBottom: "100%" }} className='aspect-ratio round'>
                <Img fluid={item.image.localFile.childImageSharp.fluid}
                  imgStyle={{ objectFit: 'cover' }}
                  objectPosition='50% 50%'
                  className=''
                />
              </div>
              <div className='copy'>
                <div className='heading'>
                  <h5>{item.title}</h5>
                </div>
                <p className='fs-85'>{item.subtitle}</p>
              </div>
            </Intro>
          )
        })}
      </div>

    </div>
  )
}