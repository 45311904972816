import './style.scss'
import React from 'react'
import Img from 'gatsby-image'
import Intro from '../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

export default function ISAdmissions({ data }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={io} id="is-admissions" className="c5 t">
      <div className="bg bg-desktop">
        <Img fluid={data.admissions_backdrop_desktop.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className=''
        />
      </div>

      <div className="bg bg-mobile">
        <Img fluid={data.admissions_backdrop_mobile.localFile.childImageSharp.fluid}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className=''
        />
      </div>

      <div className="wrap">
        <div className="leftside">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={400} mounted={true} stay={true}>

            <div className="content">
              <h2>INternational<br />STUDENT ADMISSIONS</h2>
              <p>If you are interested in pursuing your degree with MRU,<br/> here are your first steps:</p>
              <ol>
                <li> Submit your application online.</li>
                <li>Select your expected start date and program. </li>
                <li>Pay your Non-refundable Application Fee (USD $100), with a credit card. </li>
              </ol>
              <p>Prepare your admissions documents.  You will need your: </p>
              <ol>
                <li> Passport</li>
                <li>Official academic records (transcripts) with credential evaluation.  See FAQ section for more information. </li>
                <li>Record of Official English Proficiency Test </li>
                <li>Declaration and certification of finances Affidavit </li>
              </ol>
              <p>Upon submission of your admission documents, one of our friendly admission advisors will contact you to provide guidance throughout the remaining application process.</p>
              <p>E-mail for documents submission: <a href="mailto:international@mru.edu" target='blank' className='underscore'>international@mru.edu</a> </p>
              <div className="ctas">
                <div className="ctas-btns">
                  <a href="https://cms.mru.edu/wp-content/uploads/2023/10/MRU-University-Catalog-Addendum-International-Students.pdf" target='_blank' rel='noopener noreferrer' className="btn type-1"><span>Catalog</span></a>
                  <a href="#modal-mru-tuition" className="btn type-1"><span>Tuition & Enrollment Fees</span></a>
                  <a href="https://portal.mru.edu" target='_blank' rel='noopener noreferrer' className="btn type-2"><span>Apply Now</span></a>
                </div>
                <div className="ctas-mascot">
                  <Img fluid={data.zucca_mascot_image_2.localFile.childImageSharp.fluid}
                    imgStyle={{ objectFit: 'contain' }}
                    objectPosition='50% 50%'
                    className=''
                  />
                </div>
              </div>
            </div>
          </Intro>
        </div>
        <div className="rightside">
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true}>
            <div className="content">
              <h2>Obtaining your <br />
                Form I-20 and <br />
                Applying for your F-1 Student Visa
              </h2>
              <p>All international students intending to pursue studies in the United States must obtain a Form I-20, titled ‘Certificate of Eligibility for Nonimmigrant Student Status.’ This form is presented to the U.S. embassy in your country and enables you to apply for an F-1 Student Visa. To receive a Form I-20 from the University, you must meet the following requirements: </p>

              <ol>
                <li>Provide the Unconditional Acceptance Letter from MRU.</li>
                <li>Accept the offer of admission and be aware of all financial requirements for the program, including tuition fees and living expenses.</li>
                <li>Submit a valid copy of your passport, passport expiration date must be longer than 6 months.</li>
                <li>Provide Evidence of Financial Capacity, International students must demonstrate to the U.S. embassy that they have sufficient funds to cover their tuition fees and living expenses while in the United States. Self-funded students can present their own funds, while others can present funds from a financial sponsor or a combination of both. Financial sponsors, such as parents, family members, or relatives, are acceptable to prove that you have the required financial capacity to study and live in the United States. You can submit a bank statement or a bank letter on official bank letterhead confirming the closing balance in the account you plan to use to demonstrate financial capacity. The required minimum balance to demonstrate financial capacity for international students to study at MRU is listed in FAQ.Pay your Non-refundable Application Fee (USD $100), with a credit card. </li>
              </ol>
            </div>
          </Intro>
        </div>
      </div>
    </div>
  )
}